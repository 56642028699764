<template>
    <div class="flex">
        <div class="q-no">{{ no }}.</div>
        <div class="qa-container">
            <div class="flex">
                <a-select class="at-select q-type" v-model="params.type" @change="triggerChange">
                    <a-select-option value="radio">单选题</a-select-option>
                    <a-select-option value="checkbox">多选题</a-select-option>
                    <a-select-option value="text">问答题</a-select-option>
                </a-select>
                <at-input
                        class="at-input qq-input"
                        placeholder="请输入问题描述"
                        v-model="params.title"
                        @change="triggerChange"
                />
            </div>
            <div class="ans-container" v-if="params.type === 'text' ">


            </div>
            <div class="ans-container" v-else>

                <template v-for="(option,ok) in options">
                    <div class="q-item" :key="ok" v-if="!option.delete">

                        <span class="q-no">{{ getNo(ok) }}.</span>

                        <at-input @change="triggerChange" class="q-input" v-model="option.title"
                                  placeholder="请输入答案内容">
                            <span class="a-del" @click="delOption(option)">删除</span>
                        </at-input>

                    </div>
                </template>

            </div>


            <div class="qa-tools">
                <div v-if="params.type !== 'text' " class="qt-btn flex flex-center" @click="addOption">
                    <img src="/assets/icons/qa-add.png" alt="">
                    <span>添加选项</span>
                </div>
                <div class="qt-btn flex flex-center" @click="delQuestion">
                    <img src="/assets/icons/qa-del.png" alt="">
                    <span>删除问题</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AtInput from "@/components/AtInput.vue";

export default {
    name: "Question",
    components: {AtInput},
    model: {
        event: "change",
        prop: "question",
    },
    props: {
        no: Number,
        question: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            params: {
                title: "",
                type: "radio",
            },
            nos: 'ABCDEFGHIJKLMNOPQRSTUVWXZY',
            options: [
                {
                    title: "",
                },
                {
                    title: "",
                },
                {
                    title: "",
                },
            ],
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        question() {
            this.init()
        }
    },
    methods: {
        init() {
            if (this.question.type) {
                this.params.type = this.question.type
            }

            this.params.title = this.question.title
            this.params.id = this.question.id

            if (this.question.options) {
                this.options = this.question.options
            }

            this.triggerChange()
        },
        delQuestion() {
            this.$confirm({
                title: "确定要删除这个问题吗？",
                onOk: () => {
                    this.$emit('delete')
                }
            })
        },
        addOption() {
            if (this.options.length >= 12) return;

            this.options.push({
                title: ""
            })

            this.triggerChange()
        },
        triggerChange() {
            let params = this.params;

            if (this.params.type === 'text') {
                delete params.options
            } else {
                params.options = this.options
            }

            this.$emit('change', params)
        },
        delOption(option) {
            if (option.id) {

                this.$set(option, 'delete', 1)

                this.triggerChange()

                return
            }

            let idx = this.options.indexOf(option)

            this.options.splice(idx, 1)

            this.triggerChange()
        },
        getNo(qk) {

            let no = 0

            for (const questionsKey in this.options) {

                if (questionsKey >= qk) return this.nos[no]

                if (!this.options[questionsKey].delete) no++;
            }

            return this.nos[no];
        }
    },
}
</script>

<style scoped lang="less">
.q-no {
    line-height: 40px;
    padding-right: 8px;
    width: 30px;
    font-size: 14px;
    color: #333333;
}

.q-type {
    background: #EEEEEE;
    border: 1px solid #EEEEEE;
    margin-right: 8px;

    /deep/ .ant-select-selection {
        background-color: transparent;
    }
}

.ans-container {
    margin-top: 24px;
}

.qa-container {
    width: 544px;
}

.qq-input {
    flex: 1;
}

.q-item {
    display: flex;
    margin-top: 24px;

    .q-input {
        flex: 1;
    }
}

.qa-tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
}

.qt-btn {
    width: 100px;
    height: 28px;
    background: #F7F7F7;
    font-size: 14px;
    color: #333333;
    line-height: 28px;
    cursor: pointer;

    &:first-child {
        margin-right: 12px;
    }

    &:last-child {
        margin-right: 0;
    }

    img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
}

.a-del {
    height: 40px;
    line-height: 40px;
    margin-right: 24px;
    color: rgba(251, 176, 59, 1);
    cursor: pointer;
}

</style>